"use client";

import NameForm from "@/components/NameForm";
import { Button } from "@/components/ui/Button";
import Eyebrow from "@/components/ui/Eyebrow";
import Fade from "@/components/ui/Fade";
import Heading from "@/components/ui/Heading";
import PageWrapper from "@/components/ui/PageWrapper";
import VideoPage from "@/components/VideoPage";
import thumbnail from "@/images/letter-thumbnail.jpg";
import signatureImage from "@/images/signature.png";
import useCookie from "@/lib/use-cookie";
import localFont from "next/font/local";
import Image from "next/image";
import { useEffect, useState } from "react";

const biro = localFont({ src: "../../../fonts/Biro_Script_reduced.otf" });
const klaklak = localFont({ src: "../../../fonts/Klaklak-Regular.otf" });

export default function Site2() {
  const [step, setStep] = useState("intro");

  const [name, setName] = useCookie("name", false);

  // Only run client side if mounted.
  const [isMounted, setIsMounted] = useState(false);

  const isValidName = typeof name === "string" && name.length > 0;

  const [isNameSubmitted, setIsNameSubmitted] = useState(isValidName);

  const [isIntroSubmitted, setIsIntroSubmitted] = useCookie(
    "letter-intro-submitted",
    false,
  );

  const setNameCallback = (name) => {
    // first word only.
    setName(name.split(" ")[0]);
    setIsNameSubmitted(true);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    let newStep;

    if (!isNameSubmitted) {
      newStep = "welcome";
    } else if (!isIntroSubmitted) {
      newStep = "intro";
    } else {
      newStep = "letter";
    }

    setStep(newStep);
  }, [isIntroSubmitted, isNameSubmitted]);

  if (!isMounted) return null;

  const gotoPlanhart = () => {
    const url =
      process.env.NEXT_PUBLIC_PLANHART_URL ??
      "https://planhart.com/challenge.asp";
    const slug = btoa(JSON.stringify({ name }));
    document.location = `${url}/${slug}`;
  };

  return (
    <PageWrapper>
      <main className="flex max-w-full grow flex-col items-center justify-center gap-4">
        <Fade isVisible={step === "welcome"}>
          <NameForm onSubmit={setNameCallback}>
            <Eyebrow>Welcome to Box Two!</Eyebrow>
            <Heading>
              Before We Proceed&hellip;
              <br />
              What&rsquo;s Your Name?
            </Heading>
          </NameForm>
        </Fade>

        <Fade isVisible={step === "intro"} variant="full">
          <VideoPage mediaId="2e9gua30y1" thumbnail={thumbnail}>
            <Eyebrow className="max-w-[18rem]">
              You&apos;ve proven yourself, and you&apos;re ready for the final
              phase. Watch the video above, then click proceed to begin.
            </Eyebrow>
            <Button onClick={() => setIsIntroSubmitted(true)} variant="gold">
              Continue
            </Button>
          </VideoPage>
        </Fade>

        <Fade isVisible={step === "letter"}>
          <div
            className={
              klaklak.className +
              " -mt-5 w-screen bg-paper-texture bg-[558px_auto] bg-top bg-no-repeat py-20 text-sm leading-4 text-black"
            }
          >
            <div className="m-auto max-w-[334px]">
              <h1 className={biro.className + " text-3xl"}>{name}</h1>
              <p className="mb-6 mt-3">
                If you&apos;re reading this, it means you, my dear {name}, have
                proven your wits, your wisdom, and your ability to work with a
                partner. So it’s time to work with a NEW partner from this point
                on. The ultimate partner, if I do say so myself… ME.
              </p>
              <p className="mb-6">
                You see, no one has ever won the Planhart Cortex Conclave alone,
                because it&apos;s{" "}
                <span className="-m-1.5 bg-[url('../images/red-circle.svg')] bg-contain bg-center p-1.5">
                  impossible to do it solo
                </span>
                . It takes two players to solve the puzzle, and that&apos;s
                where you come in!
              </p>
              <p className="mb-6">
                Twist the knob NEXT to the puzzle envelope in the lower area of
                your game box - that will unlock a secret drawer. Inside are SIX
                objects I don&apos;t know about, and I&apos;ve been given clues
                you&apos;re unaware of. We&apos;re a team now.
              </p>
              <p className="mb-6">
                TOGETHER, we&apos;ll solve the puzzle. Once you&apos;re ready,
                head to the PCC website and use my user code below. Enter that
                code and let the adventure begin!
              </p>

              <p className="">
                Your Teammate,
                <Image
                  src={signatureImage}
                  alt="Neil Patrick Harris"
                  width={88}
                  style={{
                    marginTop: "-1.5rem",
                    display: "inline-block",
                  }}
                />
                <br /> Neil Patrick Harris
              </p>

              <p className="mb-2 mt-4 text-center text-3xl">User Code: 618</p>

              <Button variant="black" onClick={() => gotoPlanhart()}>
                Continue
              </Button>
            </div>
          </div>
        </Fade>
      </main>
    </PageWrapper>
  );
}
